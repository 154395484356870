import axios, { AxiosResponse } from 'axios';
import { all, fork, put, select, take, call } from 'redux-saga/effects';
import * as actions from './actions';
import { Vehicle } from './types';
import { showAxiosError } from 'vms/app/AppToaster';
import { PART_SAVE_SUCCESS, PART_DELETE_SUCCESS } from 'vms/features/parts/actions';
import { selectVehicles } from './selectors';
import { Part } from 'vms/features/parts/types';
import { VendorSublet } from 'vms/features/sublet/types';
import { UPDATE_VENDOR_SUBLET_SUCCESS } from 'vms/features/sublet/actions';

function* refreshVehicle(vehicleId?: string) {
  const vehicles: { [id: string]: Vehicle } = yield select(selectVehicles);
  if (!vehicleId || !vehicles[vehicleId]) {
    return;
  }
  try {
    const response: AxiosResponse<Vehicle> = yield axios.get<Vehicle>(
      `/vehicles/${vehicleId}/`
    );
    yield put(actions.vehicleApplyUpdate(response.data));
  } catch (e) {
    showAxiosError(e);
    throw e;
  }
}

function* loadVehicle(action: {
  type: typeof actions.LOAD_VEHICLE;
  payload: PromisePayload<Vehicle> & { vehicleId: string };
}) {
  const { resolve, reject, vehicleId } = action.payload;
  try {
    const response: AxiosResponse<Vehicle> = yield axios.get<Vehicle>(
      `/vehicles/${vehicleId}/`
    );
    yield put(actions.loadVehiclesSuccess([response.data]));
    resolve(response.data);
  } catch (e) {
    reject(e);
  }
}

function* watchCostChange() {
  while (true) {
    let vehicleId = '';
    const action = yield take([
      PART_SAVE_SUCCESS,
      PART_DELETE_SUCCESS,
      UPDATE_VENDOR_SUBLET_SUCCESS,
    ]);
    if (action.type === PART_SAVE_SUCCESS || action.type === PART_DELETE_SUCCESS) {
      const part: Part = action.payload.part;
      vehicleId = part.vehicle.id;
    } else if (action.type === UPDATE_VENDOR_SUBLET_SUCCESS) {
      const vendorSublet: VendorSublet = action.vendorSublet;
      vehicleId = vendorSublet.vehicle;
    }
    yield fork(refreshVehicle, vehicleId);
  }
}

function* watchLoadVehicle() {
  while (true) {
    const a = yield take(actions.LOAD_VEHICLE);
    yield call(loadVehicle, a);
  }
}

export default function* root() {
  yield all([fork(watchCostChange), fork(watchLoadVehicle)]);
}
