import { AxiosError } from 'axios';
import { formatDistanceStrict, format } from 'date-fns';
import { Positionable } from 'vms/common/types';

export const parseDateTime = (dt: string): Date => {
  const d = Date.parse(dt);
  if (isNaN(d)) {
    return new Date();
  }
  return new Date(d);
};

export const timeAgo = (dt: string, now?: string) =>
  formatDistanceStrict(now ? parseDateTime(now) : new Date(), parseDateTime(dt), {
    includeSeconds: false,
  });

export const timeAgoFromDate = (dt: Date, now?: Date) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const dt2 = now || new Date();
  const days = Math.round(Math.abs(dt2.getTime() - dt.getTime()) / MS_PER_DAY);
  if (days > 1) {
    return `${days} days`;
  }
  return formatDistanceStrict(dt2, dt, { includeSeconds: false });
};

export const timeAgoTZ = (dt: string) => timeAgoFromDate(parseDateTime(dt));

export const formatDate = (dt: Date, dateOnly = false) =>
  dateOnly ? format(dt, 'MM/dd/yyyy') : format(dt, 'MM/dd/yyyy HH:mm');

export const reformatDTZ = (dt: string) => format(parseDateTime(dt), 'MM/dd/yyyy HH:mm');

export const isoFormat = (dt: Date, dateOnly = false) => {
  return dateOnly
    ? format(dt, 'yyyy-MM-dd')
    : format(dt, 'yyyy-MM-dd') + 'T' + format(dt, 'HH:mm:ss');
};

export class BadRequestError {
  message: string;
  data: {};
  code = 'BAD_REQUEST_ERROR';
  name = 'BAD_REQUEST_ERROR';
  originalError: AxiosError;

  constructor(data: {}, error: AxiosError) {
    this.message = 'Bad request error.';
    this.data = data;
    this.originalError = error;
  }
}

/**
 * Change positions of items in map.
 *
 * @param items Items to reorder
 * @param newItem Item with new position
 */
export const reorder = (
  items: { [id: string]: Positionable },
  newItem: Positionable
): void => {
  const oldItem = items[newItem.id];
  let minPosition: number;
  let maxPosition: number;
  let sign: number;
  if (oldItem.position < newItem.position) {
    [minPosition, maxPosition] = [oldItem.position, newItem.position];
    sign = -1;
  } else if (newItem.position < oldItem.position) {
    [minPosition, maxPosition] = [newItem.position, oldItem.position];
    sign = 1;
  } else {
    return;
  }
  Object.keys(items)
    .filter(id => items[id].position >= minPosition && items[id].position <= maxPosition)
    .forEach(id => {
      if (items[id].position === oldItem.position) {
        items[id] = { ...items[id], position: newItem.position };
      } else {
        items[id] = { ...items[id], position: items[id].position + sign };
      }
    });
};

/**
 * Transform all nested fields of data into Date
 * @param fields List of fields to transform
 * @param data Object to process
 */
export const transformDateFields = (fields: string[], data: any) => {
  const transform = (obj: any) => {
    if (!obj) {
    } else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        transform(obj[i]);
      }
    } else if (typeof obj === 'object') {
      fields.forEach(field => {
        if (typeof obj[field] === 'string') {
          // create Date from array, so TZ not applied
          // otherwize you can get another day
          // > new Date([2018,09,10])
          // > Mon Sep 10 2018 00:00:00 GMT-0500  <-- we use this
          // > new Date(2018,09,10)
          // > Wed Oct 10 2018 00:00:00 GMT-0500  <-- wrong month
          // > new Date('2018-09-10')
          // > Sun Sep 09 2018 19:00:00 GMT-0500  <-- wrong date
          const dt = new Date(obj[field].split('-'));
          obj[field] = dt;
        }
      });
      Object.keys(obj).forEach(key => {
        transform(obj[key]);
      });
    }
  };
  transform(data);
};
