import { History } from 'history';
import { RootState } from '../rootReducer';
import { AppStore } from './configureStore.prod';

let configureStore: (initialState: RootState, history: History) => AppStore;
if (process.env.NODE_ENV === 'production') {
  configureStore = require('./configureStore.prod').default;
} else {
  configureStore = require('./configureStore.dev').default;
}

export default configureStore;
