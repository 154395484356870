import * as React from 'react';
import { RouteComponentProps, withRouter, match } from 'react-router';
import * as H from 'history';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'vms/app/rootReducer';
import { STATUS_MAP, STATUS_CODES } from 'vms/features/vehicles/constants';
import { User } from 'vms/features/accounts/types';
import * as _ from 'lodash-es';
import { selectCurrentUser } from 'vms/features/accounts/selectors';
import { selectVehicleCounts } from 'vms/features/vehicles/selectors';
import { hideIfInWork } from 'vms/features/accounts/wrappers';

const NavSection = ({
  to,
  name,
  count,
  isActive,
}: {
  to: string;
  name: string;
  count?: number;
  isActive?(match: match<{}>, location: H.Location): boolean;
}) => (
  <NavLink className="nav-section" to={to} isActive={isActive}>
    {name}
    <br />
    {count}
  </NavLink>
);

const NavSectionContainer = ({ status, counts }: { status: number; counts: any }) => {
  const pathname = `/vehicles/status/${STATUS_MAP[status].slug}/`;
  const search = '?ordering=in_status_age';
  return (
    <NavSection
      to={pathname + search}
      name={STATUS_MAP[status].name.toUpperCase()}
      count={counts[status] || 0}
      isActive={(_m, l) => {
        if (l.pathname === pathname) {
          return true;
        }
        return false;
      }}
    />
  );
};

const STATUSES = [
  STATUS_CODES.INCOMING,
  STATUS_CODES.BULLPEN,
  STATUS_CODES.PRE_INSPECTION,
  STATUS_CODES.GPS,
  STATUS_CODES.TECHNICIAN_INSPECTION,
  STATUS_CODES.TECHNICIAN_BUILD,
  STATUS_CODES.VENDOR_SUBLET,
  STATUS_CODES.HEAVY_LINE,
  STATUS_CODES.QC,
  STATUS_CODES.STATE_INSPECTION,
  STATUS_CODES.DETAIL,
  STATUS_CODES.IN_TRANSIT,
  STATUS_CODES.BRANCH,
  STATUS_CODES.LOT_REPAIR,
  STATUS_CODES.REJECTED,
  STATUS_CODES.DONOR,
];

class Sidebar extends React.Component<
  { counts: any; user: User | null } & RouteComponentProps<{}>
> {
  render() {
    const { user, counts } = this.props;
    return (
      <aside>
        <NavSection
          to="/vehicles/?ordering=in_status_age"
          name="ALL VEHICLES"
          count={_.values(counts).reduce((r, e) => r + e, 0)}
          isActive={(_m, l) => l.pathname === '/vehicles/'}
        />
        {STATUSES.map(s => (
          <NavSectionContainer key={s} status={s} counts={counts} />
        ))}
        <NavSection
          to="/vehicles/in_work/?ordering=in_status_age&amp;in_work=true"
          name="IN WORK"
          isActive={(_m, l) => l.pathname === '/vehicles/in_work/'}
        />
        <NavSection to="/parts/" name="ALL PARTS ORDERED" />
        <NavSection to="/charts/" name="VEHICLE STATUS CHARTS" />
        {user && user.is_admin && <NavSection to="/settings/" name="SETTINGS" />}
      </aside>
    );
  }
}

const mapState = (state: RootState) => ({
  user: selectCurrentUser(state),
  counts: selectVehicleCounts(state),
});

export default hideIfInWork(withRouter(connect(mapState)(Sidebar)));
