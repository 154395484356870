import {
  call,
  all,
  fork,
  takeEvery,
  put,
  select,
  take,
  CallEffect,
  SelectEffect,
  PutEffect,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import * as actions from './actions';
import * as inspectionApi from 'vms/features/inspection/api';
import { InspectionTaskResult, InspectionTask } from 'vms/features/inspection/types';
import {
  inspectionResultAddPart,
  updateInspectionResults,
} from 'vms/features/inspection/actions';
import * as partsApi from './api';
import {
  Part,
  PartDefinition,
  PartAddDialogEditableData,
  PartAddDialogReadOnlyData,
  PartAddDialogInitialData,
  PartVendor,
  PartEditDialogReadOnlyData,
  PartEditDialogEditableData,
  PartEditDialogInitialData,
} from './types';
import {
  selectIsPartDefinitionsLoadedByTask,
  selectPartDefinitions,
  selectPartAddDialogSaveData,
} from './selectors';
import {
  selectInspectionCategories,
  selectInspectionTasks,
  selectInspectionResults,
  selectCachedInspectionTasksListByCategory,
  selectCachedActiveAvailableInspectionResultsByTask,
} from 'vms/features/inspection/selectors';
import { showGlobalError } from 'vms/app/AppToaster';
import { selectIsVehicleInInitialInspectionStatus } from 'vms/features/vehicles/selectors';
import { BadRequestError } from 'vms/common/utils';
import { MCMC_TIRES_CATEGORY_ID } from 'vms/features/inspection/constants';

export function* getBreadcrumbs(inspectionTaskId: string) {
  const categories = yield select(selectInspectionCategories);
  const tasks = yield select(selectInspectionTasks);
  const task = tasks[inspectionTaskId];
  const category = categories[task.category];
  return [category.name, task.name];
}

export function* getPartDefinition(inspectionTaskId: string, part?: Part) {
  let definition: string | null = null;
  let parentDefinition: string | null = null;
  const isPartDefsLoadedByTask = yield select(selectIsPartDefinitionsLoadedByTask);
  if (!isPartDefsLoadedByTask[inspectionTaskId]) {
    const response: AxiosResponse<PaginatedResults<PartDefinition>> = yield call(
      partsApi.fetchPartDefinitions,
      {
        params: { inspection_task: inspectionTaskId },
      }
    );
    yield put(actions.loadPartDefinitionsSuccess(response.data.results));
    yield put(actions.definitionsLoadedForTask(inspectionTaskId));
  }
  if (part && part.definition) {
    definition = part.definition;
    parentDefinition = (yield select(selectPartDefinitions))[definition].parent || null;
    if (definition && !parentDefinition) {
      parentDefinition = definition;
      definition = null;
    }
  }
  return { definition, parentDefinition };
}

export function* savePart(part: Partial<Part>, image?: File) {
  let form: any = part;
  if (image) {
    form = new FormData();
    Object.keys(part)
      .filter(key => part[key] !== undefined)
      .forEach(key => form.append(key, part[key]));
    form.append('image', image);
  } else {
    form.image = undefined;
  }
  const response: AxiosResponse<Part> = yield call(partsApi.savePart, form);
  yield put(inspectionResultAddPart(response.data.inspection_result, response.data));
  yield put(actions.savePartSuccess(response.data));
  return response;
}

export function* createInspectionTaskResult(
  taskId: string,
  vehicleId: string,
  data?: Partial<InspectionTaskResult>
) {
  let response: AxiosResponse<InspectionTaskResult>;
  try {
    response = yield call(inspectionApi.saveInspectionTaskResult, {
      ...(data || {}),
      task: taskId,
      vehicle: vehicleId,
    });
  } catch (err) {
    if (err.response && err.response.status === 400) {
      throw new BadRequestError(err.response.data, err);
    }
    throw err;
  }
  yield put(updateInspectionResults(response.data));
  return response.data.id;
}

// we have to match tires somehow, this is quick fix, implement a proper version
// in bright future
const HARDCODE_TIRES_DEFINITIONS = {
  'f75408f9-8b22-4a5a-9236-f98be9d97ab0': '8b863a87-3b20-46e6-a1ba-d2bd5992d444',
  'bd15e06e-d5ee-4c17-bbad-5f0e14c56dc7': '5a60552d-d1c2-41c8-914a-c527824d84ce',
  'd9f4b32b-aa9e-468d-9843-76e8bcfe84c9': 'b10275d2-870c-417d-aeb3-cb1eab1d1d84',
  'b005e947-b85b-4ab9-985a-78dbac75e551': '467c873f-2ea4-4e80-aa55-db6709779ac7',
};

export function* saveDialogPart(
  partData: Partial<Part>,
  partId: string | undefined,
  vehicleId: string,
  parentDefinition: string | null,
  taskId: string | null,
  image: File | undefined
) {
  let inspectionResult = partData.inspection_result;
  if (!inspectionResult) {
    const isInInitialInspectionStatus = yield select(
      selectIsVehicleInInitialInspectionStatus,
      vehicleId
    );
    inspectionResult = yield call(createInspectionTaskResult, taskId, vehicleId, {
      inspection_status: isInInitialInspectionStatus ? 'ATTN' : undefined,
    });
  }
  const data = {
    ...partData,
    id: partId,
    inspection_result: inspectionResult,
    definition: partData.definition || parentDefinition || '',
  };
  yield call(savePart, data, image);
}

export function* saveManyDialogParts(
  editableData: PartAddDialogEditableData,
  partId: string,
  vehicleId: string
) {
  const tasks: InspectionTask[] = yield select(
    selectCachedInspectionTasksListByCategory,
    editableData.inspectionCategoryId
  );
  const results: { [task: string]: InspectionTaskResult } = yield select(
    selectCachedActiveAvailableInspectionResultsByTask,
    vehicleId
  );
  for (let i = 0; i < tasks.length; i++) {
    const taskId = tasks[i].id;
    const {
      parentDefinition,
      task,
      inspectionCategoryId,
      createForAllTasks,
      image,
      isCustom,
      ...partData
    } = editableData;
    const definition = isCustom ? null : HARDCODE_TIRES_DEFINITIONS[taskId];
    yield call(
      saveDialogPart,
      {
        ...partData,
        definition,
        inspection_result: results[taskId] && results[taskId].id,
      },
      partId,
      vehicleId,
      parentDefinition,
      taskId,
      image
    );
  }
}

export function* savePartAddDialogData() {
  const {
    editableData,
    readOnlyData,
  }: ReturnType<typeof selectPartAddDialogSaveData> = yield select(
    selectPartAddDialogSaveData
  );
  const partId = readOnlyData.id;
  if (
    editableData.createForAllTasks &&
    editableData.inspectionCategoryId === MCMC_TIRES_CATEGORY_ID &&
    !partId
  ) {
    yield call(saveManyDialogParts, editableData, partId, readOnlyData.vehicleId);
  } else {
    const {
      parentDefinition,
      task,
      inspectionCategoryId,
      createForAllTasks,
      image,
      isCustom,
      ...partData
    } = editableData;
    yield call(
      saveDialogPart,
      partData,
      partId,
      readOnlyData.vehicleId,
      parentDefinition,
      task,
      image
    );
  }
}

export function* loadPartAddDialogData(
  payload: PartAddDialogInitialData
): IterableIterator<
  | { readOnlyData: PartAddDialogReadOnlyData; editableData: PartAddDialogEditableData }
  | SelectEffect
  | CallEffect
  | PutEffect<ReturnType<typeof actions.closePartAddDialog>>
> {
  const { inspectionTaskId, vehicleId, inspectionResultId, part } = payload;
  let definition = null;
  let parentDefinition;
  try {
    const res = yield call(getPartDefinition, inspectionTaskId, part);
    definition = res.definition;
    parentDefinition = res.parentDefinition;
  } catch (error) {
    showGlobalError();
    yield put(actions.closePartAddDialog());
    return;
  }
  let breadcrumbs: string[] = [];
  let inspectionCategoryId: string | null = null;
  if (inspectionTaskId) {
    breadcrumbs = yield call(getBreadcrumbs, inspectionTaskId);
    const tasks: { [id: string]: InspectionTask } = yield select(selectInspectionTasks);
    inspectionCategoryId = tasks[inspectionTaskId]
      ? tasks[inspectionTaskId].category
      : null;
  }
  const data: {
    readOnlyData: PartAddDialogReadOnlyData;
    editableData: PartAddDialogEditableData;
  } = {
    readOnlyData: {
      id: part ? part.id : undefined,
      vehicleId,
      isQuickRequest: !inspectionTaskId,
      image: part && part.image ? part.image : null,
      breadcrumbs,
    },
    editableData: {
      inspection_result: inspectionResultId,
      parentDefinition,
      definition,
      createForAllTasks: false,
      task: inspectionTaskId || null,
      inspectionCategoryId,
      isCustom: !(part && part.definition),
      name: part ? part.name : '',
      notes: part ? part.notes : '',
    },
  };
  return data;
}

export function* loadPartEditDialogData(
  payload: PartEditDialogInitialData
): IterableIterator<
  | { readOnlyData: PartEditDialogReadOnlyData; editableData: PartEditDialogEditableData }
  | SelectEffect
> {
  const { part } = payload;
  const results = yield select(selectInspectionResults);
  const inspectionTaskId =
    results[part.inspection_result] && results[part.inspection_result].task;
  const data: {
    readOnlyData: PartEditDialogReadOnlyData;
    editableData: PartEditDialogEditableData;
  } = {
    readOnlyData: {
      id: part ? part.id : undefined,
      image: part && part.image ? part.image : null,
      vehicleId: part.vehicle.id,
      breadcrumbs: [],
      isQuickRequest: false,
    },
    editableData: {
      ...part,
      image: null,
      inspection_result: part.inspection_result,
      task: inspectionTaskId,
    },
  };
  return data;
}

export function* partSaveWithPromise(action: {
  type: typeof actions.PART_SAVE;
  payload: { part: Partial<Part> } & PromisePayload<Part>;
}) {
  const { resolve, reject, part } = action.payload;
  try {
    const response: AxiosResponse<Part> = yield call(savePart, part);
    resolve(response.data);
  } catch (e) {
    reject(e);
  }
}

function* loadPartVendors(action: {
  type: typeof actions.LOAD_PART_VENDORS;
  payload: PromisePayload<PartVendor[]>;
}) {
  const { resolve, reject } = action.payload;
  try {
    const response: AxiosResponse<PaginatedResults<PartVendor>> = yield axios.get<
      PaginatedResults<PartVendor>
    >('/part-vendors/', { params: { page_size: 1000 } });
    yield put(actions.loadPartVendorsSuccess(response.data.results));
    resolve(response.data.results);
  } catch (e) {
    reject(e);
  }
}

function* watchLoadPartVendors() {
  const a = yield take(actions.LOAD_PART_VENDORS);
  yield call(loadPartVendors, a);
}

function* watchPartSave() {
  yield takeEvery(actions.PART_SAVE, partSaveWithPromise);
}

export default function* root() {
  yield all([fork(watchPartSave), fork(watchLoadPartVendors)]);
}
